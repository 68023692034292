<template>
    <div class="wrap">
        <Play :spaceId="spaceInfo.spaceId" :spaceNm="spaceInfo.spaceNm" v-if="ShowPlay" @closePlay="ShowPlay = false" />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <div class="space_pc">
            <section class="main space4_4 space4_5">
                <!-- 로딩중 -->
                <div class="main_box" v-if="lodingStatus">
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" /> 에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span> 중인<br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                        <button class="opacity cursor_init">다음</button>
                    </div>
                </div>
                <!-- 로딩 완료 -->
                <div class="main_box" v-else>
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" /> 에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span
                        >중인 <br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악이 선곡 되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt="" /></div>
                            <img src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                        <button @click="linkPayment()">다음</button>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 로딩중 -->
        <div class="space_mo h_100" v-if="lodingStatus">
            <section class="main space4_4">
                <div class="main_box">
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" /> 에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span> 중인<br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button style="text-align: center" class="opacity3">다음</button>
            </div>
        </div>
        <!-- 모바일 로딩 완료 -->
        <div class="space_mo h_100" v-else>
            <section class="main space4_4 space4_5">
                <div class="main_box">
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" /> 에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span
                        >용 <br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악이 선곡 되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt="" /></div>
                            <img src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button style="text-align: center" @click="linkPayment()">다음</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import Play from '@/components/modal/Space/PaymentFinished.vue';
import { leaveChannel } from '@/assets/js/webcast';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import { moveUserSpaceInfo, mySpaceInfo, selectCurationInfoAll } from '@/service/api/profileApi';
import { getUserCase } from '@/service/api/userApi';
export default defineComponent({
  name: 'FinishedCuration',
  data () {
    return {
      spaceInfo: {},
      lodingStatus: true,
      ShowPlay: false,
      popupModalText: '',
      isPopUpModal: false,
      spacePayAt: '',
      curationInfList: [],
      curationId: ''
    };
  },
  components: {
    Play,
    'popup-modal': PopUpModal
  },
  created () {},

  async mounted () {
    await this.getSpaceInfo();
    if (Object.keys(this.$route.params).includes('curationid')) {
      this.curationId = this.$route.params.curationid;
      this.setTimeCurationList();
    }
    await this.setSpacePayAt();
    setTimeout(this.loding, 3000);
  },
  methods: {
    async setTimeCurationList () {
      const { data } = await selectCurationInfoAll(this.spaceInfo.spaceId);
      const { resultCd, result } = data;
      if (resultCd === '0000') {
        this.curationInfList = result;
      } else {
        //
        this.curationInfList = [];
      }
    },
    isIncludeTimeCuration () {
      if (this.curationId) {
        const now = new Date();
        const curTime = now.getHours() * 60 + now.getMinutes();

        const day = now.getDay() === 0 ? 7 : now.getDay();

        const find = this.curationInfList.find(item => {
          const dayOfWeek = item.dayOfWeek;
          if (item.startTime && item.endTime) {
            const start = item.startTime.split(':');
            const startHour = start[0];
            const startMin = start[1];

            const end = item.endTime.split(':');
            const endHour = end[0];
            const endtMin = end[1];

            const startTime = parseInt(startHour) * 60 + parseInt(startMin);
            const endTime = parseInt(endHour) * 60 + parseInt(endtMin);
            if (
              curTime >= startTime &&
              curTime <= endTime &&
              item.curationId === this.curationId &&
              dayOfWeek.includes(day)
            ) {
              if (this.curationId !== this.$store.getters['player/getCreatorCurationId']) {
                return true;
              }
              return false;
            } else {
              if (this.curationId === this.$store.getters['player/getCreatorCurationId']) {
                if (curTime < startTime || curTime > endTime) {
                  return true;
                } else if (!dayOfWeek.includes(day)) {
                  return true;
                }
              }
              return false;
            }
          } else {
            return this.curationId === this.$store.getters['player/getCreatorCurationId'];
          }
        });
        if (find) {
          return find;
        } else {
          const creatorCurationId = this.$store.getters['player/getCreatorCurationId'];
          if (creatorCurationId) {
            const findCratorCurationId = this.curationInfList.find(item => item.curationId === creatorCurationId);
            return !findCratorCurationId;
          }
        }
      } else {
        return false;
      }
    },
    async linkPayment () {
      // 결제페이지로 이동
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var spaceId = this.spaceInfo.spaceId;

      if (this.spaceInfo.spaceType === 'Building') {
        axios
          .post('/api/profile/updateCarPayAt', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              router.push({ name: 'MySpace' });
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        var userId = VueCookies.get('userId');

        // 카드 등록 여부 확인
        const card = await axios.post('/api/product/getMyCardInfo', { userId }, { headers }).catch(e => {
          console.log(e);
        });

        // 체험용으로 업데이트 조건
        // 카드 미등록, 체험용 시작하기 버튼을 클릭하여 들어온 경우
        if (card.data.resultCd === '0000' && card.data.result == null && this.$store.state.trialStatus) {
          // 등록된 카득가 없을 경우 체험판으로
          const spacePayAt = 'T';
          try {
            const res = await axios.post(
              '/api/profile/updateTrialSpaceInfo',
              { userId, spaceId, spacePayAt, isNew: 'Y' },
              { headers }
            );

            if (res.data.resultCd === '0000') {
              // 이미 체험용 스페이스를 생성했던 계정일 경우
              if (res.data.result === 0) {
                router.push({ name: 'SpacePayment' });
                return false;
              }

              if (this.isIncludeTimeCuration() || this.curationInfList.length === 1) {
                if (this.$store.state.channelId !== '') {
                  leaveChannel();
                }
                VueCookies.set('spaceId', spaceId);
                this.$VideoPlayer.onPause();
                const userId = this.$cookies.get('userId');
                moveUserSpaceInfo(spaceId, userId);

                this.$router
                  .push({ name: 'PlayerView' })
                  .catch(() => {})
                  .finally(() => {
                    this.$gatag.event('space', {
                      spaceType: this.spaceInfo.spaceType
                    });
                    this.$gatag.event('curation');
                    this.$store.dispatch('player/addCronJob');
                  });
              } else {
                this.$router.push({ path: `/curationsettingtime/${spaceId}` }).finally(() => {
                  this.$store.dispatch('player/addCronJob');
                });
              }
            }
          } catch (err) {
            if (err.response) {
              const { data } = err.response;
              const { resultCd, resultMsg } = data;
              if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
                this.popupModalText = resultMsg;
                this.isPopUpModal = true;
                return false;
              }
            }
            console.error('updateTrialSpaceInfo error : ', err);
          }
        } else {
          const { data } = await getUserCase(userId);
          const { result, resultCd } = data;
          if (resultCd === '0000') {
            const { trialYn, spaceYn } = result;
            if (trialYn === 'Y' && spaceYn === 'N') {
              if (this.$store.getters['coupon/getSelectCupon']) {
                router.push({ name: 'SpacePayment' });
              } else {
                const spacePayAt = 'T';
                try {
                  const res = await axios.post(
                    '/api/profile/updateTrialSpaceInfo',
                    { userId, spaceId, spacePayAt, isNew: 'Y' },
                    { headers }
                  );

                  if (res.data.resultCd === '0000') {
                    // 이미 체험용 스페이스를 생성했던 계정일 경우
                    if (res.data.result === 0) {
                      router.push({ name: 'SpacePayment' });
                      return false;
                    }

                    if (this.isIncludeTimeCuration() || this.curationInfList.length === 1) {
                      if (this.$store.state.channelId !== '') {
                        leaveChannel();
                      }
                      VueCookies.set('spaceId', spaceId);
                      this.$VideoPlayer.onPause();
                      const userId = this.$cookies.get('userId');
                      moveUserSpaceInfo(spaceId, userId);

                      this.$router
                        .push({ name: 'PlayerView' })
                        .catch(() => {})
                        .finally(() => {
                          this.$gatag.event('space', {
                            spaceType: this.spaceInfo.spaceType
                          });
                          this.$gatag.event('curation');
                          this.$store.dispatch('player/addCronJob');
                        });
                    } else {
                      this.$router.push({ path: `/curationsettingtime/${spaceId}` }).finally(() => {
                        this.$store.dispatch('player/addCronJob');
                      });
                    }
                  }
                } catch (err) {
                  if (err.response) {
                    const { data } = err.response;
                    const { resultCd, resultMsg } = data;
                    if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
                      this.popupModalText = resultMsg;
                      this.isPopUpModal = true;
                      return false;
                    }
                  }
                  console.error('updateTrialSpaceInfo error : ', err);
                }
              }
            } else {
              if (this.spacePayAt === 'Y' || this.spacePayAt === 'T') {
                if (this.isIncludeTimeCuration() || this.curationInfList.length === 1) {
                  if (this.$store.state.channelId !== '') {
                    leaveChannel();
                  }
                  this.$cookies.set('spaceId', spaceId);
                  this.$VideoPlayer.onPause();
                  this.$router
                    .push({ name: 'PlayerView' })
                    .catch(() => {})
                    .finally(() => {
                      this.$gatag.event('space', {
                        spaceType: this.spaceInfo.spaceType
                      });
                      this.$gatag.event('curation');
                      this.$store.dispatch('player/addCronJob');
                    });
                } else {
                  this.$router.push({ path: `/curationsettingtime/${spaceId}` }).finally(() => {
                    this.$store.dispatch('player/addCronJob');
                  });
                }
              } else {
                router.push({ name: 'SpacePayment' });
              }
            }
          } else {
            router.push({ name: 'SpacePayment' });
          }
        }
      }
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    async getSpaceInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.spaceInfo = res.data.result;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async setSpacePayAt () {
      if (this.spaceInfo.spaceId) {
        try {
          const { data } = await mySpaceInfo(this.spaceInfo.spaceId);
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            const { spacePayAt } = result;
            this.spacePayAt = spacePayAt;
          } else {
            this.spacePayAt = '';
          }
        } catch (error) {
          this.spacePayAt = '';
        }
      }
    },
    loding () {
      this.lodingStatus = false;
    },
    failUpdateCuration () {
      alert('선호음악 저장에 실패하였습니다.');
    }
  },
  computed: {
    carCompleteCuration1 () {
      if (Object.keys(this.spaceInfo).length > 0) {
        return this.spaceInfo.carPurpose.length > 1
          ? `${this.spaceInfo.carPurpose[0]}+${this.spaceInfo.carPurpose.length - 1}`
          : this.spaceInfo.carPurpose[0];
      }
      return '';
    },
    carCompleteCuration2 () {
      return `${this.spaceInfo.spaceNm}`;
    }
  }
});
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
